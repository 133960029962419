<template>
  <b-modal
    id="modal-stater-statistics"
    size="lg"
    centered
    hide-header
    cancel-variant="none"
    cancel-title="Quay lại"
    ok-variant="primary"
    :no-close-on-backdrop="true"
    @ok="hideModal"
    :style="{ width: '120%' }"
  >
    <b-card-header class="banned-background-color-primary">
      <div class="d-flex justify-content-between align-items-center block-header">
        <p class="mb-0 title-modal color-primary">
          {{ $t('golf_booking_statistic_title') }}
        </p>
        <feather-icon
          icon="XIcon"
          size="24"
          class="cursor-pointer color-primary"
          @click="hideModal"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <div>
        <b-table
        :fields="fields"
        style="text-align: center;font-size: 14px;"
        :items="listBookingStatistic"
        bordered
        class="table-parent"
      >
        <template #cell(Title)="data">
          {{ $t(data.item.Title) }}
        </template>
      </b-table>
      </div>
    </b-card-body>
  </b-modal>
</template>
<script>
export default {
  name: 'StarterBookingStatistics',
  props: [
    'fields', 'listBookingStatistic'
  ],
  data() {
    return {
     
    }
  },
  methods: {
    open() {

    },
    hideModal() {
      this.$bvModal.hide('modal-stater-statistics')
    },
  },
}
</script>
<style lang="scss" scoped>

.modal-content {
  display: flex;
  justify-content: center; /* Căn giữa theo chiều ngang */
  align-items: center; /* Căn giữa theo chiều dọc */
}



</style>