import { members } from '@/api/members'
import { booking } from '@/api/booking'
import { courseSetting } from '@/api/course-setting'
import { report } from '@/api/report'
import DxButton from 'devextreme-vue/button'
import 'devextreme/dist/css/dx.light.css'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver-es'
import dayjs from 'dayjs'
import { convertUTC } from '@/@core/utils/utils'
import { commonServices } from '@/api/common-services'
import Treeselect from '@riophae/vue-treeselect'
import { getCssText } from '@/api/css-text'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import StarterBookingStatistics from './StarterBookingStatistics.vue'
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'       
import SidebarDetailBooking from '@/views/modules/booking-list/SidebarDetailBooking/SidebarDetailBooking.vue'
import { starter } from '@/api/starter'
import { auto } from '@popperjs/core'

const dataGridRef = 'tablePackage'

export default {
  data() {
    return {
      keyWord: {
        Date: this.getUTC('start', 'day'),
        BookingStatus: ['BOOKED','CHECK_IN','TEEOFF','FINISH','CHECK_OUT',],
        Shift:['M','A','E'],
        TeeId: [],
        TypeView:'TEE',
        IsGetBlankTeeTime:false,
      },
      companies: [],
      listFieldHide: [],
      hileColum: false,
      source: [],
      courses: [],
      listBooking: [],
      listBookingByJson: null,
      colorRow: {
        CheckIn: null,
        CheckOut: null,
        TeeOff: null,
        Finish: null,
      },
      caddy: '',
      caddyName: '',
      caddyCode: '',
      isSelect: false,
      obj: null,
      json: null,
      isLoading: false,
      isBusyTable: false,
      currentPage: 1,
      perPage: 50,
      dataGridRef: 'tablePackage',
      optionPerPage: [10, 50, 100],
      configTable: [
        {
          caption: ' Tee ',
          field: 'Tee',
          isShow: true,
          cellTemplate: 'tee-time',
          alignment: 'center',
          width: '80',
        },
        {
          caption: 'Time Turn',
          field: 'TimeTurn',
          cellTemplate: 'time-turn',
          isShow: true,
          alignment: 'center',
          width: '10%',
        },
        {
          caption: this.$t('golf_boongking_group_name'),
          field: 'GroupName',
          isShow: true,
          alignment: 'center',
          width: '10%',
        },
        {
          caption: this.$t('golf_common_booking_code'),
          field: 'BookingCode',
          isShow: true,
          alignment: 'center',
          width: '10%',
        },
        {
          caption: this.$t('golf_common_first_and_last_name'),
          field: 'MemberName',
          isShow: true,
          alignment: 'center',
          width: '10%',
        },
        {
          caption: 'Golf Class',
          field: 'GolfClassName',
          isShow: true,
          alignment: 'center',
          width: '5%',
        },
        {
          caption: 'Trạng thái',
          field: 'BookingDetailStatus',
          cellTemplate: 'booking-detail-status',
          isShow: true,
          alignment: 'center',
          width: '120',
        },
        {
          caption: 'BDC/BagTag',
          field: 'BDCBagtag',
          isShow: true,
          alignment: 'center',
          width: '5%',
        },
        {
          caption: 'Locker',
          field: 'LockerCode',
          isShow: true,
          alignment: 'center',
          width: '5%',
        },
        {
          caption: this.$t('golf_caddy_code'),
          field: 'CaddyCode',
          isShow: true,
          alignment: 'center',
          width: '5%',
        },
        {
          caption: 'Tên Caddy',
          field: 'CaddyName',
          isShow: true,
          alignment: 'center',
          width: '10%',
        },
        {
          caption: 'Buggy',
          field: 'BuggyCode',
          isShow: true,
          alignment: 'center',
          width: '5%',
        },
        {
          caption: this.$t('golf_detail_booking_number_of_hole'),
          field: 'NumberOfHole',
          isShow: true,
          //cellTemplate: 'tee-time',
          alignment: 'center',
          width: '7%',
        },
        {
          caption: this.$t('golf_starter_tee_off'),
          field: 'TeeOff',
          isShow: true,
          cellTemplate: 'tee-off',
          alignment: 'center',
          width: '7%',
        },
        {
          caption: this.$t('golf_starter_finish'),
          field: 'Finish',
          isShow: true,
          cellTemplate: 'Finish',
          alignment: 'center',
          width: '7%',
        },
        {
          caption: 'Email',
          field: 'Email',
          isShow: true,
          alignment: 'center',
          width: '5%',
        },
        {
          caption: this.$t('golf_common_phone'),
          field: 'PhoneNumber',
          isShow: true,
          alignment: 'center',
          width: '10%',
        },
        {
          caption: this.$t('golf_member_country'),
          field: 'Nationality',
          isShow: true,
          alignment: 'center',
          width: '10%',
        }, 
        {
          caption: this.$t('golf_common_note'),
          field: 'Note',
          isShow: true,
          // cellTemplate: 'note',
          width: '150',
          alignment: 'center',
          allowEditing: false,
        },
        {
          caption: this.$t('golf_common_note') + ' Starter',
          field: 'NoteOfStarter',
          isShow: true,
          width: '150',
          // cellTemplate: 'note',
          alignment: 'center',
          allowEditing: true,
        },
      ],
      timeZoneOffSet: localStorage.getItem('timeZoneOffset'),
      KeyWordDataProps: {
        BookingId: null,
        BookingDetailId: null,
        KeyWord: null,
        BookingCode: null,
        GroupName: null,
        OpenDate: null,
        BDC: [],
      },
      shiftTitle : {
        key:"Title",
        label:this.$t('golf_booking_statistic_shift')
      },
      totalTitle : {
        key:"Total",
        label:this.$t('golf_booking_statistic_total')
      },
      Title: [ ],
      Content: [],
      listBookingStatistic: [],
      fields: [],
      optionBookingStatus: [
        {
          id: 'BOOKED',
          label: 'Booked',
        },
        {
          id: 'CHECK_IN',
          label: 'Check in',
        },
        {
          id: 'TEEOFF',
          label: 'TeeOff',
        },
        {
          id: 'FINISH',
          label: 'Finish',
        },
        {
          id: 'CHECK_OUT',
          label: 'Check out',
        },
        {
          id: 'CANCELLED',
          label: 'Hủy',
        },
        {
          id: 'NO_SHOW',
          label: 'No show',
        },
       
      ],
      optionShift: [
        {
          id: 'M',
          label: this.$t('golf_booking_morning_shift'),
        },
        {
          id: 'A',
          label: this.$t('golf_booking_afternoon_shift')
        },
        {
          id: 'E',
          label: this.$t('golf_booking_night_shift'),
        }
      ],
      optionView: [
        'TEE','TIME'
      ],
      optionTee:[],
      ROLE_OF_SALE: null,
      ROLE_OF_GM: null,
      ROLE_OF_CASHIER: null,
      defaultCheckIn: null,
      defaultPrintBDCinCheckIn: null,
      listUserOfSale: null,
      listUserOfGM: null,
      listUserOfCashier: null,
      windowHeight: 0,
      heightTable: auto
    }
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRequiredRule,
    DxRangeRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport,
    SidebarDetailBooking,
    Treeselect,
    StarterBookingStatistics,
  },
  computed: {
    dataGrid() {
      return this.$refs[dataGridRef].instance
    },
    // configTableHide() {
    //   let list = []
    //   this.configTable.forEach(x => {
    //     if(x.field != 'StarterGroupCode' && x.field != 'action') {
    //       list.push({
    //         ...x,
    //         id: x.field,
    //         label: x.caption
    //       })
    //     } 
    //   })
    //   return list
    // },
  },
   async created() {
  await this.getListCourseAndTee()
   await this.getListCourse_RCO01()
    this.getColorRowStarter()
    
    this.getRoleId()
    this.getListCompany_RCL01()
    this.getListResources_RSO01() 
    await this.getListBookingStarter_RTS02(this.keyWord)
  },
  watch: {
    ROLE_OF_SALE(value) {
      this.getCashierRole(value)
    },
    ROLE_OF_GM(value) {
      this.getCashierRole(value)
    },
    ROLE_OF_CASHIER(value) {
      this.getCashierRole(value)
    },
    windowHeight(value) {
      if(value > 600 ) {
      }
    },
    keyWord: {
      handler(newVal) {
        // Gọi phương thức khi keyWord thay đổi
        this.getListBookingStarter_RTS02(newVal);
      },
      deep: true, // Theo dõi toàn bộ các thuộc tính bên trong keyWord
    },
  
  },
  mounted() {
    this.windowHeight = window.innerHeight
  },
  methods: {
    dayjs,
    // lấy danh sách TA
    async getListCompany_RCL01() {
      const response = await members.getCompanies()
      if (response.Status === '200') {
        this.companies = response?.Data?.Client
      }
    },
    // lấy danh sách nguồn
    async getListResources_RSO01() {
      const response = await booking.getListResources()
      if (response.Status === '200') {
        this.source = response?.Data?.Source
      }
    },
    // lấy danh sách sân
    async getListCourse_RCO01() {
      const response = await courseSetting.getListCourse()
      if (response.Status === '200') {
        this.courses = response?.Data?.Course
        this.keyWord.CourseId = this.courses.map(x => x.Id)
      }
    },
    // HideColumn() {
    //   this.configTable.forEach(x => {
    //     this.listFieldHide.forEach(y => {
    //       if(x.field === y) {
    //         x.isShow = false
    //       }
    //     })
    //   })
    // },
    // DisplayColumn() {
    //   this.configTable.forEach(x => {
    //     this.listFieldHide.forEach(y => {
    //       if(x.field === y) {
    //         x.isShow = true
    //       }
    //     })
    //   })
    // },
    // get api RF01
     async getListBookingStarter_RTS02( request) {
      this.isLoading = true
      await starter.api_GET_LIST_BOOKING_API_STARTER_RTS02(request).then(response => {
        
        if (response.Status == 200) {
          this.listBooking = response.Data.TeeSheet

          this.fields = []
          this.listBookingStatistic = []
          this.fields.push(this.shiftTitle)
          const _tee = response.Data.Title.map(item => ({
            key: item.TeeId,
            label: item.TeeName
          }));
          this.fields =[...this.fields, ..._tee]
          this.fields.push(this.totalTitle)
          response.Data.Content.forEach(x=>{
            const titleItem = {
              Title : x.Title,
              Value : x.Value
            }
            x.Statistic.push(titleItem)
            const pivot= x.Statistic.reduce((obj, item) => {
              obj[item.Title] = item.Value;
              return obj;
            }, {});
            this.listBookingStatistic.push(pivot)
          })
        } else {
          this.listBooking = []
        }
      })
    
   
    
   
    this.isLoading = false
    },
    // function search khi ấn tìm kiếm
    search() {
      this.getListBookingStarter_RTS02(this.keyWord)
    },
    async getColorRowStarter() {
      await commonServices
        .getCommon({ KeyGroup: 'BOOKING_CONFIG' })
        .then(res => {
          // this.listWorkingCaddyStatus = res?.Data.find(x => x.KeyCode === 'BOOKING_CADDY_STATUS_ALLOW_BOOKING').KeyValue
          // this.DefaultSearch = res?.Data.find(x => x.KeyCode === 'BOOKING_DEFAULT_SEARCH').KeyValue
           this.colorRow.CheckIn = res?.Data?.find(x => x.KeyCode === 'COLOR_BOOKING_CHECKIN').KeyValue
          // this.isShowPopupTime = res?.Data?.find(x => x.KeyCode === 'STARTER_SHOW_POPUP_TEEOFF_FINISH_TIME').KeyValue
           this.colorRow.CheckOut = res?.Data?.find(x => x.KeyCode === 'COLOR_BOOKING_CHECKOUT').KeyValue
           this.colorRow.TeeOff = res?.Data?.find(x => x.KeyCode === 'COLOR_BOOKING_TEEOFF').KeyValue
           this.colorRow.Finish = res?.Data?.find(x => x.KeyCode === 'COLOR_BOOKING_FINISH').KeyValue
          // this.heightTable = Number(res?.Data?.find(x => x.KeyCode === 'STARTER_HEIGHT_OF_TABLE_LIST_BY_PIXEL').KeyValue)
          // this.IsAutoChangeCaddy = res?.Data.find(x => x.KeyCode === 'BOOKING_AUTO_UPDATE_HOLE_OF_CADDIE').KeyValue
          this.listFieldHide = JSON.parse(res?.Data?.find(x => x.KeyCode === 'STARTER_HIDE_COLUMN').KeyValue)
          // this.isManualCheckDupCaddie = res?.Data.find(x => x.KeyCode === 'BOOKING_MANUAL_CHECK_DUP_CADDIE').KeyValue
          // this.checkSelectCaddyInTable = res?.Data.find(x => x.KeyCode === 'STARTER_ALLOW_SELECT_CADDY_FROM_DROPDOWN_LIST').KeyValue
          // this.ColorFillColumnBDC = res?.Data.find(x => x.KeyCode === 'STARTER_FILL_COLOR_BY_BDC_COLUMN').KeyValue
          //this.HideColumn()

          if(this.heightTable == 0) {
            this.heightTable = this.windowHeight - 250
          }
          this.$forceUpdate()
        })
    },
     async getListCourseAndTee() {
      // Lấy danh sách sân
      await courseSetting
        .getListCourse()
        .then(res => {
          if (res) {
            this.listCourse = res.Data.Course
            this.listCourse.forEach(course => {
              const body = {
                Course: {
                  Id: course.Id,
                },
              }
              // Lấy danh sách tee của sân
                 courseSetting
                .getListTee(body)
                .then(res => {
                  if (res.Status == '200') {
                    const _tee =   res.Data.Tee.map(item => ({
                      id: item.Id,
                      label: item.TeeName
                    }));  
                    const Id = res.Data.Tee.map(item => item.Id)
                    this.keyWord.TeeId =[...this.keyWord.TeeId, ...Id]
                    this.optionTee =[...this.optionTee, ..._tee]
                  }
                })
            })

          }
        })
        

       
    },
    findColorColumn(data) {
      var backgroudcolor = data.TeeBackgroundColor
      var color =''
      if (data.TeeColor && data.TeeColor.trim() !== '') {
        color = 'color:'+data.TeeColor+';'
      } 
      return `background-color: ${backgroudcolor}; ${color}`
    },
    openStarterBookingStatistics(){
      this.$bvModal.show('modal-stater-statistics')
    },
    findColorColumnStatus(data) {
      var color = null
      // if (data.BookingDetailStatus === 'CHECK_IN' && !data.EntryTime && !data.ExitTime) {
      //   color = this.colorRow.CheckIn
      // } else if (data.BookingDetailStatus === 'TEEOFF' && data.EntryTime && !data.ExitTime) {
      //   color = this.colorRow.TeeOff
      // } else if (data.ExitTime && data.BookingDetailStatus !== 'FINISH') {
      //   color = this.colorRow.Finish
      // } else if (data.BookingDetailStatus === 'CHECK_OUT') {
      //   color = this.colorRow.CheckOut
      // }

      switch (data.BookingDetailStatus) {
        case "CHECK_IN":
          color = this.colorRow.CheckIn
          break;
        case "TEEOFF":
          color = this.colorRow.TeeOff
          break;
        case "FINISH":
          color = this.colorRow.Finish
          break;
        case "CHECK_OUT":
          color = this.colorRow.CheckOut
          break;
        default:
          color = null
      }
      return `background-color: ${color}`
    },
    exportExcel() {
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Booking')
      const timeZone = localStorage.getItem('timeZone')
      exportDataGrid({
        component: this.dataGrid,
        worksheet,
        autoFilterEnabled: true,
        PrintSelectedRowsOnly: true,
        topLeftCell: { row: 4, column: 1 },
        customizeCell: function(options) {
          const { gridCell, excelCell } = options;
          if(gridCell.rowType === 'data') {
            if(gridCell.column.dataField == 'OpenDate') {
              excelCell.value =  convertUTC(gridCell.value,'DD/MM/YYYY HH:mm')
            }
            if(gridCell.column.dataField == 'TeeOff') {
              excelCell.value =  convertUTC(gridCell.value,'HH:mm')
            }
            if(gridCell.column.dataField == 'Finish') {
              excelCell.value =  convertUTC(gridCell.value,'HH:mm')
            }
      } }
      }).then(cellRange => {
        // header
        const headerRow = worksheet.getRow(2)
        headerRow.height = 30
        worksheet.mergeCells(2, 1, 2, 8)

        headerRow.getCell(1).value = `DANH SÁCH BOOKING (Ngày ${this.convertUTC(this.keyWord.Date, 'DD/MM/YYYY')})`
        headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 }
        headerRow.getCell(1).alignment = { horizontal: 'center' }

        // footer
        const footerRowIndex = cellRange.to.row + 2
        const footerRow = worksheet.getRow(footerRowIndex)
        worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8)
        footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true }
        footerRow.getCell(1).alignment = { horizontal: 'right' }
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Booking.xlsx')
        })
      })
    },
    // viewDetailBooking(item) {
    //   if(item.column.dataField != 'SgmsSynchronized') {
    //     this.KeyWordDataProps.BookingId = item.data.BookingId
    //     this.KeyWordDataProps.BookingDetailId = item.data.Id
    //     this.KeyWordDataProps.OpenDate = item.data.OpenDate
    //     this.KeyWordDataProps.BookingCode = item.data.BookingCode
    //     this.$refs.SidebarDetailBookingList.openDetailBookingList()
    //   }
    // //   console.log(item)
    //   // this.KeyWordDataProps.BDC.push(item.data.BDC)
      
    // },
    async getRoleId() {
      await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' }).then(res => {
        if (res.Status === '200') {
          this.ROLE_OF_SALE = Number(res?.Data.find(item => item.KeyCode === 'ROLE_OF_SALE').KeyValue)
          this.ROLE_OF_GM = Number(res?.Data.find(item => item.KeyCode === 'ROLE_OF_GM').KeyValue)
          this.ROLE_OF_CASHIER = Number(res?.Data.find(item => item.KeyCode === 'ROLE_OF_CASHIER').KeyValue)
          this.defaultCheckIn = res?.Data.find(item => item.KeyCode === 'DEFAULT_CHECKIN').KeyValue
          this.defaultPrintBDCinCheckIn = res?.Data.find(item => item.KeyCode === 'PRINT_BDC').KeyValue
        }
      })
    },
    async getCashierRole(Id) {
      const body = {
        RoleId: [Id],
      }
      await booking.api_RCS01(body).then(res => {
        if (res.Status === '200') {
          if (Id === this.ROLE_OF_SALE) {
            this.listUserOfSale = res?.Data?.Cashier.map(x => ({
              id: x.Id,
              label: x.CashierName,
            }))
          }
          if (Id === this.ROLE_OF_GM) {
            this.listUserOfGM = res?.Data?.Cashier.map(x => ({
              id: x.Id,
              label: x.CashierName,
            }))
          }
          if (Id === this.ROLE_OF_CASHIER) {
            this.listUserOfCashier = res?.Data?.Cashier.map(x => ({
              id: x.Id,
              label: x.CashierName,
            }))
          }
        }
      })
    },
    convertUTCRF01(value, format) {
      if (value) {
        if (value.search(/[^.]*$/) === 0) {
          // Không có chữ Z thì mới add Z
          if (value.search(/[^Z]*$/) === 0) {
            value += 'Z'
          }
        } else {
        // Nếu không tìm thấy Z mới add thêm Z
        // eslint-disable-next-line no-lonely-if
          if (value.search(/[^Z]*$/) === 0) {
            value = value.replace(/[^.]*$/, 'ABC').replace('.ABC', 'Z')
          }
        }
    
        const timeZone = localStorage.getItem('timeZone')
    
        if (format) {
        // return this.dayjs(value).utc().tz(timeZone).format()
    
          return dayjs(value).tz(timeZone).format(format)
        }
      }
      // return dayjs(date).toDate()
    },
    printTeeSheet() {
      const routeData = this.$router.resolve({ name: 'PrintTeeSheet', params: { time: this.keyWord.Date }, query: { C: JSON.stringify(this.keyWord.CourseId) } })
      window.open(routeData.href, '_blank')
    },
    printStarterFormList() {
      const prtHtml = document.getElementById('starter-form-list-print').innerHTML

      let stylesHtml = `<style type="text/css">.ez-icon {display: none} ${getCssText()}</style>`
      for (const node of [...document.querySelectorAll('link')]) {
        stylesHtml += node.outerHTML
      }

      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,height=900,toolbar=0,scrollbars=0,status=0')

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
        </head>
        <body>
          <div style="text-align: center; margin: 10px 0">
            <h2>Điều phối khách</h2>
            <i>Thời gian in: ${this.utcConvertToLocalTimeZone(new Date(), 'DD/MM/YYYY HH:mm:ss')}</i>
          </div>
          ${prtHtml}
          <style>@media print { @page { margin:0; } *{font-family:  Arial, Helvetica, sans-serif ;} .table-responsive {overflow-x: hidden;}}</style>
          <script> window.onload = function (e){ window.print(); }</script>
        </body>
      </html>`)
      WinPrint.document.close()
    },
    async reSynSgms(data) {
      const body = {
        MessageId: data
      }
      await starter.api_BG_UBK01S(body).then(res => {
        this.showResToast(res)
        if (res.Status == '200') {
          this.search()
        }
      })
    },
    async SynSgms(data) {
      const body = {
        MessageId: null,
        BookingDetailId: data
      }
      await starter.api_BG_UBK01S(body).then(res => {
        this.showResToast(res)
        if (res.Status == '200') {
          this.search()
        }
      })
    },
  },
}